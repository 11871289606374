import React, {useEffect, useState} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Form } from "react-bootstrap"
import {Link, navigate, useIntl} from "gatsby-plugin-intl"
import instance from "../../components/api/httpclient";
import {useMediaQuery} from "react-responsive";
import {FormattedDate} from "react-intl";
import moment from "moment";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 })
  return isDesktop ? children : null
};

const ContactForm = ({location}) => {
  const intl = useIntl();
  const currentUser = typeof window !== 'undefined' ? JSON.parse(localStorage.getItem('currentUser')) : {};
  const [message, setMessage] = useState('');
  const [recipient, setRecipient] = useState('');
  const [vouchers, setVouchers] = useState('');
  const [selectedVoucher, setSelectedVoucher] = useState('');
  const [check, setCheck] = useState(false);

  useEffect(() =>{

    if (location.state) {

      console.log(location);
      setRecipient(location.state.type);


      if (location.state.type == "basis") {
        instance.get("/vouchers/user",
          {
            headers: {
              'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
            }
          })
          .then((response) => {
            console.log(response);
            setVouchers(response.data.vouchers);

          }).catch((error) => {
          console.log('error', error);
        });
      }
    }

  }, [])

  const checkboxChange = () => {
    setCheck(!check);
  };

  const handleClick = (event) => {
    event.preventDefault();
    if (message !== "") {
      instance.post("contact",
        {
          message: message,
          lang: intl.locale,
          recipient: recipient,
          voucher:selectedVoucher
        },
        {
          headers: {
            'Authorization': typeof window !== 'undefined' ? localStorage.getItem('token') : ''
          }
        })
        .then((response) => {
          if (response.status === 200) {
            navigate('/contact/message-sent')
          } else if (response.status === 401) {
            navigate('/sessions/logout');
          }
        }).catch((error) => {
        console.log('error', error);
      });
    }
  }

  const getVouchers = () => {
    if (vouchers.length > 0) {
      return (
        <Form.Control as="select" onChange={(event) => setSelectedVoucher(event.target.value)}>
          <option value={"0"}>{intl.formatMessage({ id: "contact.form_voucher.select_voucher" })}</option>
          {vouchers.map(voucher => (
            <option value={voucher.voucher_id}>{moment(voucher.voucher_date, moment.ISO_8601).format('DD/MM/YYYY')} - {voucher.reward_label}</option>
          ))}
        </Form.Control>
      )
    }
  };

    return (
      <Layout>
        <SEO title={intl.formatMessage({ id: "pages_meta_title.contact.contact-form" })} />
        <Link to="/contact/how-can-help-you" className="black mb-4 mt-0 back-link single-page"><span className="icon icon-chevron"></span>{intl.formatMessage({ id: "generic.nav_buttons.back_button" })}</Link>

        <div className="white-container mb-5">

          <h1 className="with-border-top with-border-bottom text-uppercase">{intl.formatMessage({ id: "contact.form_reward.title" })}</h1>

          <p className="mb-4">{intl.formatMessage({ id: "contact.form_reward.description" })}</p>


          <Form className="row" onSubmit={(event) => handleClick(event)}>

            <div className="col-md-6 col-lg-4">
              <Form.Group>
                <Form.Label className="required">{intl.formatMessage({ id: "contact.form_reward.email" })}</Form.Label><span className="icon-check ml-2 d-inline-block"></span>
                <Form.Control type="email" className="" value={currentUser ? currentUser.email : ""} />
                <small className="form-text form-validate-error d-none"><span>X</span>Please enter a valid email</small>
              </Form.Group>
            </div>


            <div className="col-md-6 col-lg-8">
              {recipient == "basis" && (vouchers.length > 0) &&
              <Form.Group className="checkbox-custom mt-4">
                <input type="checkbox" id="vouchers" name="vouchers" className="form-control"
                       checked={check ? check : ""} onChange={() => checkboxChange()}/>
                <label htmlFor="vouchers" title="" className="form-label">
                  {intl.formatMessage({id: "contact.form_voucher.check_box"})}
                </label>
              </Form.Group>
              }
              <Form.Group className="mb-lg-4">
                { recipient=="basis" && check && (vouchers.length > 0) &&
                <Form.Label>{intl.formatMessage({ id: "contact.form_voucher.vouchers" })}</Form.Label>

                }
                { recipient == "basis" && check && (vouchers.length > 0) &&
                  getVouchers()
                }
              </Form.Group>

              <Form.Group className="mb-lg-4">
                <Form.Label className="required">{intl.formatMessage({ id: "contact.form_reward.message" })}</Form.Label>

                <Form.Control as="textarea" rows="5"
                onChange={(event) => {setMessage(event.target.value)}}
                placeholder={intl.formatMessage({ id: "contact.form_reward.message" })} name="textarea-exemple" className="form-control">
                </Form.Control>
              </Form.Group>
            </div>


            <div className="w-100"></div>

            <div className="col-md-4">
              <div className="required-field mb-4">*{intl.formatMessage({ id: "contact.form_reward.mandatory_fields" })}</div>
            </div>

            <div className="col-md-4 ml-md-auto">
              <button type="submit" className={message === '' ? "button disabled red mx-auto mr-md-0" : "button red mx-auto mr-md-0"} >{intl.formatMessage({ id: "contact.form_reward.send_button" })}</button>
            </div>

          </Form>

        </div>

      </Layout>
    )
}

export default ContactForm
